import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

const RegisterComponent = () => {

  return (

    <div className='new-letter' style={{ backgroundColor: "#ceffee", paddingTop: 50 }} >
      <FontAwesomeIcon style={{ fontSize: 24, marginTop: 20 }} icon={faEnvelopeSquare} />
      <p style={{ fontSize: 18, fontWeight: 'bold'}}>
        contact@getasmilefix.com
      </p>
      <span>&copy;2024 Cure Little</span>
    </div>

  );
}

export default RegisterComponent;