import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Logo from '../assets/cl_logo.png';
import '../css/App.css';


function MantraComp() {

  return (

    <div className='App-body-header'>
      <img src={Logo} style={{ height: '10vh' }} />
    </div>

  );
}

export default MantraComp;
