import React, { useState, useEffect } from 'react';
import '../css/Body.css';
import '../css/App.css';
import '../css/Services.css';

import { Container, Row, Col } from 'react-bootstrap';

import WorksComponent from './works';
import MoreComponent from './more';

const ServiceToggle = () => {

  const [isWorks, setWords] = useState("service-btn-selected");
  const [isServices, setServices] = useState("service-btn");
  const [worksComponent, setWorksComponent] = useState("works-visible");
  const [servicesComponent, setServicesComponent] = useState("service-invisible");

  const toggleServices = () => {
    setWords("service-btn")
    setServices("service-btn-selected")
    setServicesComponent("service-visible")
    setWorksComponent("works-invisible")
  }

  const toggleWorks = () => {
    setWords("service-btn-selected")
    setServices("service-btn")
    setWorksComponent("works-visible")
    setServicesComponent("service-invisible")
  }

  return (
    <div
      style={{ backgroundColor: "#2da57c", marginTop: 10, paddingBottom: 15, paddingTop: 5 }}
    >

<Container>

<Row style={{ textAlign: "center" }}>
  <Col>
    <button
      className={isWorks}
      onClick={toggleWorks}
    >
      Lets have a chat
    </button>
  </Col>
  <Col>
    <button
      className={isServices}
      onClick={toggleServices}
    >
      Need Assistance
    </button>
  </Col>

</Row>
</Container>

      <div className={worksComponent} >
        <MoreComponent />
      </div>
      <div className={servicesComponent} >
        {/* <WorksComponent /> */}
        <h3 style={{paddingTop: "50px", paddingBottom: "50px"}}>Coming soon!</h3>
      </div>


    </div>
  );
}

export default ServiceToggle;