import '../css/App.css';
import React from 'react';

import Register from '../components/register'
import Mantra from '../components/mantra';
import Col_1 from '../components/col-1';
import ServiceToggle from '../components/serviceToggle';
import Subscribe from '../components/subscribe'

function Home() {


  return (
    <div className='App'>
        <Mantra />
        {/* <Subscribe /> */}
        <ServiceToggle />
        <Col_1 />
        <Register />

    </div>
  );
}

export default Home;

