import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Container, Row, Col } from 'react-bootstrap';
import office from '../assets/office.jpg'


function Col_1() {

  const styles = {
    backgroundSize: 'cover', // Optional: Adjust background size
    backgroundPosition: 'center', // Optional: Adjust background position
    width: '100%',
    marginTop: 65,
    // height: '50vh', // Adjust the height as needed
    // Add any other styles you want
  };
  return (
    // <div style={{ backgroundColor: "#fcf8eb",}}>
    <div style={styles}>
      <Container>
        <Row>
          <Col md={6}>
            <div>
              <h3>What we're solving</h3>
              <p style={{ textAlign: 'left', marginTop: '15px' }}>
              We seek to offer a convenient way for individuals to access healthcare.
              We also seek to streamline the processes of healthcare providers by integrating 
              AI technology into their websites which will a convenient and efficient way for individuals to assess their 
              healthcare remotely, potentially reducing the need for unnecessary in-person appointments and optimising resource allocation.
              </p>
            </div>
          </Col>
          
          <Col md={6} className="d-md-block">
            <div>
              <img src={office} style={{ height: 'auto', width: '100%', borderRadius: 25, }} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Col_1;
