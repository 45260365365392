import React, { Component } from 'react';
import SpeechToText from 'speech-to-text';

class SpeechRecognitionComponent extends Component {
  state = {
    interimText: '',
    listening: false,
    error: null,
  };

  componentDidMount() {
    const onAnythingSaid = (text) => {
      this.setState({ interimText: text });
      this.props.InterimText(text); // Send the interim Text back to the parent

    };

    const onEndEvent = () => {
      if (this.state.listening) {
        this.startListening();
      }
    };

    const onFinalised = (text) => {
      this.setState({ interimText: '' });
      this.props.onTextReceived(text); // Send the finalised text back to the parent
      this.props.InterimText(''); // Send the interim Text back to the parent
    };

    try {
      this.listener = new SpeechToText(onFinalised, onEndEvent, onAnythingSaid);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldListen !== this.props.shouldListen) {
      if (this.props.shouldListen) {
        this.startListening();
      } else {
        this.stopListening();
      }
    }
  }

  startListening = () => {
    this.setState({ listening: true });
    this.listener.startListening();
  };

  stopListening = () => {
    this.setState({ listening: false });
    this.listener.stopListening();
  };

  componentWillUnmount() {
    this.listener.stopListening();
  }

  render() {
    const { interimText, error, listening } = this.state;

    return (
      <div>
        {/* <h1>Speech Recognition</h1> */}
        {error && <p>Error: {error}</p>}
        {/* <p>Listening: {listening ? 'Yes' : 'No'}</p> */}
        {/* <p>Interim Text: {interimText}</p> */}
      </div>
    );
  }
}

export default SpeechRecognitionComponent;
