import React, { Component } from 'react';
import Groq from 'groq-sdk';
import SpeechRecognitionComponent from './speech';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import '../css/Services.css';
import { Container } from 'react-bootstrap';

class MoreComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatMessage: "",
      placeHolder: "Speak/Type here",
      chatResponse: "How are you feeling? \nYou can tell me about any symtoms you're having.\nExample: My gum is bleeding?",
      shouldListen: false,
      interimText: ''
    };
  }

  handleStartListening = () => {
    this.setState({
      shouldListen: true,
      placeHolder: "Listening..."
    });
  };

  handleStopListening = () => {
    this.setState({
      shouldListen: false,
      placeHolder: "Speak/Type here",
      chatMessage: ""
    });
  };

  toggleListening = () => {
    if (this.state.shouldListen) {
      this.handleStopListening();
    } else {
      this.handleStartListening();
    }
  };

  handleTextReceived = (text) => {
    this.setState({ chatMessage: text }, () => {
      if (text !== '' && this.state.chatMessage !== '') {
        this.chatGroq();
      }
    });
  };

  handleInterimText = (text) => {
    this.setState({ interimText: text });
  };

  handleSpeak = () => {
    // Check if SpeechSynthesis is available
    if ('speechSynthesis' in window) {
      // Resume audio context on iOS
      if (typeof window.speechSynthesis.resume === 'function') {
        window.speechSynthesis.resume();
      }

      const utterance = new SpeechSynthesisUtterance(this.state.chatResponse);
      utterance.rate = 0.8;

      utterance.onstart = () => {
        this.handleStopListening();
      };

      utterance.onend = () => {
        this.handleStartListening();
      };

      // Trigger speech synthesis
      window.speechSynthesis.speak(utterance);
    } else {
      console.warn("Speech synthesis not supported in this browser.");
    }
  };

  chatGroq = async () => {
    const groqKey = process.env.REACT_APP_GROQ_API_KEY;
    const groq = new Groq({
      dangerouslyAllowBrowser: true,
      apiKey: groqKey
    });

    const chatCompletion = await groq.chat.completions.create({
      messages: [{ role: 'user', content: 'Answer the following question only if it is related to health and in less than 100 words, should be sentences and give a recommend. Use \n where needed. Question: ' + this.state.chatMessage }],
      model: 'llama3-8b-8192',
    });

    this.setState({
      chatResponse: chatCompletion.choices[0].message.content,
      chatMessage: ""
    });

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.chatResponse !== this.state.chatResponse && this.state.chatResponse !== '') {
      this.handleSpeak();
    }
  }

  render() {
    return (
      <div style={{ marginTop: 20 }} >
        <SpeechRecognitionComponent
          shouldListen={this.state.shouldListen}
          onTextReceived={this.handleTextReceived}
          InterimText={this.handleInterimText}
        />

        <Container>
          <div className='col-text-p'>{this.state.chatResponse}</div>

          <input
            className='col-chat-input'
            disabled={this.state.shouldListen}
            style={this.state.shouldListen ? { backgroundColor: '#fff', cursor: 'not-allowed' } : {}}
            type='text'
            value={this.state.interimText !== '' ? this.state.interimText : this.state.chatMessage}
            placeholder={this.state.placeHolder}
            onChange={(e) => this.setState({ chatMessage: e.target.value })}
          />

          <button
            className='btn-twin-1'
            onClick={this.toggleListening}
            style={{ borderTopRightRadius: 0 }}
          >
            <FontAwesomeIcon style={{ fontSize: 30 }} icon={this.state.shouldListen ? faMicrophoneSlash : faMicrophone} />
          </button>

          <button
            className='btn-twin-2'
            style={{ borderTopRightRadius: 0 }}
            onClick={this.chatGroq}
          >
            <FontAwesomeIcon style={{ fontSize: 30, color: '#fff' }} icon={faPaperPlane} />
          </button>
        </Container>
      </div>
    );
  }
}

export default MoreComponent;
